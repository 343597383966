<template>
  <div id="404">
    <mainbox></mainbox> 

    <section class="box-error">
        <div class="container">
                   <div>
                    <div class="items-error">
                        <img src="../assets/images/Logo servises/logo.png" alt="">

                        <select @change="_onChangeLanguage" v-model="localLanguage" class="change-select ml-3">
                          <option value="ru">Ру</option>
                          <option value="uz">Ўз</option>
                          <option value="oz">O'z</option>
                      </select>

                        <h1 class="error-title">{{translation.translate("LandingPageHome", "Pagenotfound")}}</h1>
                        <p class="error-description mb--0">{{translation.translate("LandingPageHome", "mistakeDelete")}}</p>
                        <p class="error-description mt--0">{{translation.translate("LandingPageHome", "mistakeDelete2")}} <a href="https://t.me/fakturauz">{{translation.translate("LandingPageHome", "mistakeDelete3")}}</a></p>
                        <p class="error-description">{{translation.translate("LandingPageHome", "popularpages")}}</p>
                             <div class="navbar-box d-flex align-items-center justify-content-between">  
                    <ul class="error-menu">
                      <router-link :to="{ name: 'posibilities' }" class="a-dec-none"><li class="nav-item">{{translation.translate("LandingPageHome", "peculiaritiesNavbar")}}</li></router-link>
                      <router-link :to="{ name: 'price' }" class="a-dec-none"><li class="nav-item">{{translation.translate("LandingPageHome", "priceNavbar")}}</li></router-link>
                      <router-link :to="{ name: 'price' }" class="a-dec-none"><li class="nav-item">{{translation.translate("LandingPageHome", "contactNavbar")}}</li></router-link>
                      <router-link :to="{ name: 'price' }" class="a-dec-none"><li class="nav-item">{{translation.translate("LandingPageHome", "Useful")}}</li></router-link>
                      <router-link :to="{ name: 'price' }" class="a-dec-none"><li class="nav-item">{{translation.translate("LandingPageHome", "modul-link")}}</li></router-link>
                      <router-link :to="{ name: 'price' }" class="a-dec-none"><li class="nav-item">{{translation.translate("LandingPageHome", "video-link")}}</li></router-link>
                    </ul>
                  </div>
                        <router-link :to="{ name: 'LandingPage' }" class="links-navbar"><button class="btn btn-green">{{translation.translate("LandingPageHome", "home")}}</button></router-link>
                    </div>
                </div>
        </div>
    </section>

  </div>
</template>

<script defer>
import translate from "../../translation/translate";
import mainbox from "./mainbox.vue";


export default {
  name: "404",

  components: {
    mainbox,
  },
  data() {
    return {
      translation: translate,
      localLanguage: JSON.parse(localStorage.getItem("translation"))?.localeName || "uz",
    };
  },
      methods: {
         _onChangeLanguage(event) {
            translate.changeLocale(event.target.value);
            this.$emit("changeLang", event.target.value)
        },
    }
};
</script>

<style>
.items-error{
    margin: 100px 0 0 0;
}
.error-menu{
    width: 650px;
    display: flex;
    justify-content: space-between;
}
.error-title{
    text-align: start;
}
.error-description{
    margin-top: 20px;
}
.btn-green{
    background: #72bd49;
    color: white;   
}
.btn-green:hover{
    background: #62b107;
    color: white;   
}
.mb--0{
  margin-bottom: 0 !important;
}
.mt--0{
  margin-top: 0 !important;
}
</style>
