<template>
  <div id="mainbox">
    <main>
      <div class="linkWithUs">
        <div class="number-slide-out">
          <transition name="slide-fade-num">
            <span v-show="showNumber" class="number">(71) 200-00-13</span>
          </transition>
          <div
            class="link-phone"
            @mouseleave="hideNumberFunction"
            @mouseover="showNumberFunction"
          >
            <a href="tel: 71 200 00 13">
              <img src="../assets/images/svg/phone.svg" alt="png" />
            </a>
          </div>
        </div>
        <div class="link-telegram">
          <a @click="callGtag('Переход в телеграм (fixed),main_telegram_button,click')"
            href="https://t.me/fakturauz"
          >
            <img src="../assets/images/svg/telegram.svg" alt="" />
          </a>
        </div>
      </div>
    </main>
  </div>
</template>

<script defer>
import mixins from "./../mixin";

export default {
  name: "mainbox",
  data() {
    return {
      showNumber: false,
    };
  },
  mixins: [mixins],
  methods: {
    showNumberFunction() {
      this.showNumber = true;
    },
    hideNumberFunction() {
      this.showNumber = false;
    },
  },
};
</script>

<style></style>
