export default {
    methods: {
        callGtag(value) {
            /* eslint-disable no-debugger */
            var analyticsFeatures = value.split(",");
            this.$gtag.query('event', analyticsFeatures[0], {
                event_category: analyticsFeatures[1],
                event_action: analyticsFeatures[2],
            });
        }
    }
}